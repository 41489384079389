import React from "react"
import { Link } from "gatsby"
import "./Gdpr.scss"
import CircleBLur from "@common/CircleBlur/CircleBlur"
import CommonList from "@common/CommonList/CommonList"
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "../../links"

const Gdpr = () => {
  const informationItems = [
    {
      heading: "For website visitors",
      numberInList: "1.",
      description:
        "When you visit Impressit website, our server receives the following information sent from your browser:",
      listItems: [
        "Web request (e.g. date, time, etc.)",
        "IP address",
        "Browser type",
        "Browser language",
        "Cookies identifying the browser",
      ],
      lastSentence: (
        <>
          Once you visit our website, we show you a notification informing what{" "}
          <Link
            className="privacy-policy__highlited"
            to={GLOBAL_APPLICATION_INTERNAL_LINKS.COOKIE_POLICY}
          >
            data will be collected{" "}
          </Link>{" "}
          and you have an option to accept or decline data processing.
        </>
      ),
    },
    {
      heading: "For potential customers",
      numberInList: "2.",
      description:
        "When you send us a message using Impressit contact form, you are asked to provide the following data:",
      listItems: ["Full name", "Email address", "Phone number"],
      lastSentence:
        "We use this information solely for effective communication with you on your product development.",
    },
  ]

  return (
    <div className="gdpr">
      <CircleBLur
        blur="blur(25rem)"
        width="39.6875rem"
        left="-19.0625rem"
        height="39.6875rem"
      />
      <h1 className="gdpr__heading">GDPR compliance</h1>
      <p className="gdpr__description">
        To guarantee the security and transparency to our website visitors,
        subscribers,and customers, we follow the EU data protection requirements
        that are set out in the GDPR, which became enforceable on May 25, 2018.
      </p>
      <h2 className="gdpr__page-heading">What is GDPR?</h2>
      <div className="gdpr__descriptions-container">
        <p className="gdpr__text">
          GDPR stands for General Data Protection Regulation. It addresses the
          new directives within EU law to determine personal data protection of
          European Union citizens and the export of personal data outside the EU
          and the European Economic Area.
        </p>
        <p className="gdpr__text">
          Due to the fact that Impressit collaborates with the EU citizens and
          aims to provide the proper treatment of personal data of every client,
          we have taken a set of measures to get compliant with EU data
          protection requirements.
        </p>
      </div>
      <h2 className="gdpr__page-heading">
        What kind of personal information does Impressit process and how is it
        treated?
      </h2>
      <ol className="gdpr__numbered-list">
        <CircleBLur
          blur="blur(25rem)"
          width="39.6875rem"
          right="-6.5625rem"
          height="39.6875rem"
        />
        {informationItems.map((informationItem, index) => {
          const {
            heading,
            description,
            listItems,
            lastSentence,
            numberInList,
          } = informationItem
          return (
            <li key={index} className="gdpr__numbered-list-item">
              <span className="gdpr__numbered-list-item-heading">
                {numberInList}&nbsp;{heading}
              </span>
              <div className="gdpr__text-container">
                <p className="gdpr__text">{description}</p>
              </div>
              <CommonList
                top="2rem"
                bottom="2rem"
                rowGap="1.5rem"
                listItems={listItems}
              />
              {lastSentence && (
                <div className="gdpr__text-container">
                  <p className="gdpr__text">{lastSentence}</p>
                </div>
              )}
            </li>
          )
        })}
      </ol>
      <h2 className="gdpr__page-heading">Data removal</h2>
      <p className="gdpr__text">
        Impressit is a GDPR-friendly company, so you have the right to determine
        what you allow us to collect as well
        <br />
        as ask to delete all your personal data.
      </p>
    </div>
  )
}

export default Gdpr
