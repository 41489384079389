import React from "react";
import "./CommonList.scss";
import classNames from "classnames";
import { WRAP_LIST } from "@constants";

const CommonList = ({
    listItems,
    top,
    columnGap,
    listType,
    rowGap,
    maxListItemWidth,
    marginItem,
    bottom,
}) => {
    const listClassName = classNames("common-list", {
        "wrap-list": listType === WRAP_LIST,
    });

    return (
        <ul
            className={listClassName}
            style={{
                rowGap,
                columnGap,
                marginTop: top,
                marginBottom: bottom,
            }}
        >
            {listItems.map((listItem, index) => (
                <li
                    key={index}
                    style={{ maxWidth: maxListItemWidth, margin: marginItem }}
                    className="common-list__item"
                >
                    {listItem}
                </li>
            ))}
        </ul>
    );
};

CommonList.defaultProps = {
    listItems: [],
    rowGap: "0px",
    top: "0",
    bottom: "0",
};

export default CommonList;
